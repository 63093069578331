import React from 'react';
import {useSpring, animated} from 'react-spring';
import { Spring } from 'react-spring/renderprops'

import Header from './core-sections/Header.jsx';
import Footer from './core-sections/Footer.jsx';

import HomepageJumbotron from './HomepageSections/HomepageJumbotron.jsx';
import HomepagePillars from './HomepageSections/HomepagePillars.jsx';
import HomepageReasons from './HomepageSections/HomepageReasons.jsx';
import HomepageDeals from './HomepageSections/HomepageDeals.jsx';
import HomepageContact from './HomepageSections/HomepageContact.jsx';


export default function Homepage() {
    
    return (
    <Spring
        from={{ marginTop: -500 }}
        to={{ marginTop: 0}}
        config={{ duration: 400 }}
    >
        {props => (
            <div style={ props }>
                <div class="container-fluid">
                    <Header />
                    <HomepageJumbotron />
                    <HomepagePillars />
                    <HomepageReasons />
                    <HomepageDeals />
                    <HomepageContact />
                    <Footer />
                </div>
            </div>
        )}
    </Spring>);
}
