import React from 'react'

import Header from './core-sections/Header.jsx';
import Footer from './core-sections/Footer.jsx';

import background from '../images/yasin-aribuga-_XVE6pKGiLE-unsplash.jpg';

import Jumbotron from './core-sections/Jumbotron.jsx';

export default function Hosting() {

    var examples = [
        {
            'title': 'Business / Diensleistungen',
            'icon': 'fas fa-suitcase',
            'items': [
                {'image': 'dudopark', 'title': 'Dudopark', 'type': 'Website & Hosting', 'link': 'https://dudopark.de'},
                {'image': 'toyl', 'title': 'Toyl.eu', 'type': 'Website & Hosting', 'link': 'https://toyl.eu'},
                {'image': 'wirtschaftskanzlei-bruckhaus', 'title': 'Wirtschaftskanzlei Bruckhaus', 'type': 'Website & Hosting', 'link': 'https://wirtschaftskanzlei-bruckhaus.de'},
                {'image': 'omq-ausbildung', 'title': 'OMQ - Ausbildungs GmbH', 'type': 'Website & Hosting', 'link': 'https://omq-ausbildung.com'},
                {'image': 'oumengqiao', 'title': 'Oumengqiao GmbH', 'type': 'Website & Hosting', 'link': 'https://oumengqiao.com'},
                {'image': 'innovestnutrition', 'title': 'InnoVestNutrition GmbH', 'type': 'Website', 'link': 'https://innovestnutrition.com'}
            ]
        },
        {
            'title': 'Medizin',
            'icon': 'fas fa-medkit',
            'items': [
                {'image': 'tfm', 'title': 'Therapeutische Frauen-Massage', 'type': 'Website', 'link': 'https://therapeutischefrauenmassage.de'},
                {'image': 'hno-heinze', 'title': 'Dr. med. Matthias Heinze', 'type': 'Website & Hosting', 'link': 'https://hno-heinze.de'},
                {'image': 'hps', 'title': 'Freiburger Heilpflanzenschule', 'type': 'Website & Hosting', 'link': 'https://heilpflanzenschule.de'},
                {'image': 'doulasinde', 'title': 'Doulas in Deutschland e.V.', 'type': 'Website', 'link': 'https://doulas-in-deutschland.de'},
                {'image': 'sagh', 'title': 'Schweizerische Apotheker-Gesellschaft für Homöopathie', 'type': 'Website & Hosting', 'link': 'https://sagh.ch'},
                {'image': 'claudiaganss', 'title': 'Heilpraktikerin Claudia Ganß', 'type': 'Website & Hosting', 'link': 'https://claudiaganss.de'},
                {'image': 'doula-ausbildung', 'title': 'MELMIC GmbH', 'type': 'Website', 'link': 'https://doula-ausbildung.de'},
                {'image': 'dr-urig', 'title': 'Dr. med. dent. Bernd Urig', 'type': 'Website & Hosting', 'link': 'https://drurig.de'},
                {'image': 'drwerner', 'title': 'Dr. med. Gregor Werner', 'type': 'Website & Hosting', 'link': 'https://praxisdrwerner.de'},
                {'image': 'meine-doula', 'title': 'Melanie Schöne', 'type': 'Website', 'link': 'https://meine-doula.de'},
                {'image': 'oxana-karpf', 'title': 'Praxis Oxana Karpf', 'text': 'Text', 'type': 'Website & Hosting', 'link': 'https://praxis-karpf.de'}
            ]
        },
        {
            'title': 'Restaurants',
            'icon': 'fas fa-utensils',
            'items': [
                {'image': 'brasserie-du-casino', 'title': 'Brasserie Du Casino', 'type': 'Website & Hosting', 'link': 'https://brasserie-du-casino.com'},
                {'image': 'jay-and-jay', 'title': 'Jay & Jay Saarbrücken GmbH & Co. KG', 'type': 'Website & Hosting', 'link': 'https://jay-and-jay.com'}
            ]
        }
    ];

    return (
        <div className="imprint">
            <Header />
            <Jumbotron 
                background={ background }
                heading="Referenzen"
                subheading="Schauen Sie sich Beispiele unserer Arbeit an."
            />
            <div className="container-fluid page-container" style={ containerStyle }>
                <div className="container">
                    <p><strong>
                    Bemerkung: Jede dieser Webseiten wurde exakt nach Kundenauftrag gestaltet. Das Design entspricht oft nicht unseren eigenen Designvorstellungen!
                    </strong></p>
                    { examples.map(example => 
                        <div>
                            <h1><span className={example.icon}></span>&nbsp;&nbsp;&nbsp;{ example.title }</h1>
                            { example.items.map(item => 
                                <div className="showcase-example-item">
                                <div className="row">
                                    <div className="col-sm-1"></div>
                                    <div className="col-sm-4">
                                    <a href={ item.link} target="_blank" rel="noopener noreferrer">
                                        <img src={ '/images/' + item.image + '.jpg' } className="img-fluid showcase-img" alt="Responsive" />
                                    </a>
                                    </div>
                                    <div className="col-sm-6 my-auto showcase-example-item-description">
                                        <h5 className="showcase-sub-heading">{ item.type }</h5>
                                        <h2>{ item.title }</h2>
                                        <p className="text-muted"><a href={ item.link} target="_blank" rel="noopener noreferrer"><span className="fas fa-link"></span>&nbsp;&nbsp;{ item.link}</a> </p>
                                    </div>
                                </div>
                                </div>
                            ) }
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    )
}

var containerStyle = {
    backgroundColor: '#FFFFFF'
}