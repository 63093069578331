import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom'

import App from './App';
import Imprint from './components/Imprint.jsx';
import Datenschutzerklärung from './components/Datenschutzerklärung.jsx';
import Hosting from './components/Hosting.jsx';
import Showcase from './components/Showcase.jsx';

const routing = (
    <Router onUpdate={() => window.scrollTo(0, 0)}>
        <Route exact path="/" component={App} />
        <Route path="/impressum" component={Imprint} />
        <Route path="/hosting" component={Hosting} />
        <Route path="/datenschutzerklärung" component={Datenschutzerklärung} />
        <Route path="/showcase" component={Showcase} />
    </Router>
);

ReactDOM.render(routing, document.getElementById('root'));