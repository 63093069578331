import React from 'react';
import { Link } from 'react-router-dom'

export default function Footer()
{
    return (<div className="container-fluid footer" style={ containerStyle }>
        <Link to="/impressum" className="site-link">Impressum</Link>
        <Link to="/datenschutzerklärung" className="site-link">Datenschutzerklärung</Link>
    </div>);
}

var containerStyle = {
    backgroundColor: '#AFC3DE',
    'textAlign': 'center',
    'color': 'white'
}