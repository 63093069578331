import React from 'react'

import Header from './core-sections/Header.jsx';
import Footer from './core-sections/Footer.jsx';

import background from '../images/yasin-aribuga-_XVE6pKGiLE-unsplash.jpg';

import Jumbotron from './core-sections/Jumbotron.jsx';

export default function Hosting() {
    return (
        <div className="imprint">
            <Header />
            <Jumbotron 
                background={ background }
                heading="Hosting"
                subheading="Machen Sie es sich mit uns einfach."
            />
            <div className="container-fluid page-container" style={ containerStyle }>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-8">
                            <h1 className="page-heading">Unser Full-Service-Hosting:</h1>
                            <h3 className="page-heading">
                                21 € für Hosting und 1 Domain (.de, .com, .eu, .ch) inklusive / Monat 
                            </h3>
                            <p className="readable-text">
                                <span className="big-readable-text page-heading">jede weitere Domain:</span>
                            </p>
                            <ul>
                                <li>.de 2€ / Monat inkl. Hosting</li>
                                <li>.com 3€ / Monat inkl. Hosting</li>
                                <li>.eu 3€ / Monat inkl. Hosting</li>
                                <li>.ch 3€ / Monat inkl. Hosting</li>
                            </ul>
                            <p>(andere Domain-Endungen können preislich abweichen und werden individuell berechnet)</p>

                            <p className="readable-text">
                                <span className="big-readable-text page-heading">Domains ohne Inhalt (z.B. Domains von denen weitergeleitet wird):</span>
                            </p>
                            <ul>
                                <li>.de 1€ / Monat inkl. Hosting</li>
                                <li>.com 2€ / Monat inkl. Hosting</li>
                                <li>.eu 2€ / Monat inkl. Hosting</li>
                                <li>.ch 2€ / Monat inkl. Hosting</li>
                            </ul>
                            <p>(andere Domain-Endungen können preislich abweichen und werden individuell berechnet)</p>
                        </div>
                        <div className="col-sm-4">
                            <div className="card pillar-card">
                                <div className="card-body">
                                    <h3 className="card-title pillar-card-title">Leistungen</h3>
                                    <ul>
                                        <li>Tägliche Backups</li>
                                        <li>Regelmäßige Systemupdates für höchste Sicherheit</li>
                                        <li>Support, wenn die Seite nicht erreichbar ist</li>
                                        <li>Domains einrichten und Seiten aufsetzen</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card pillar-card">
                                <div className="card-body">
                                    <h3 className="card-title pillar-card-title">Jetzt persönliches Angebot einholen</h3> <br></br>
                                    <p>
                                        <a href="mailto:info@whistlingmartini.de" className="btn btn-primary btn-contact">Per E-Mail</a>
                                        <a href="tel:tel:+4915117428882" className="btn btn-info btn-contact">Per Telefon</a>
                                    </p>
                                    <p>
                                        Email:  <a href="mailto:info@whistlingmartini.de">info@whistlingmartini.de</a> <br></br>
                                        Telefon:  <a href="tel:tel:+4915117428882">+49 151 17428882</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

var containerStyle = {
    backgroundColor: '#FFFFFF'
}