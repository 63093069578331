import React from 'react'
import { Spring } from 'react-spring/renderprops'

export default function HomepageReasons() {

    const reasons = [
        {heading: "Kinderleicht", icon: "fab fa-wordpress", text: "Pflegen Sie Ihr System kinderleicht selbst - wir setzen auf Wordpress und ermöglichen damit höchste Flexibilität."},
        {heading: "Faire Preise", icon: "fas fa-euro-sign", text: "Durch hoch effiziente und professionelle Arbeit wird ihre Seite kostentechnisch kein Fass ohne Boden."},
        {heading: "Immer aktuell", icon: "fas fa-wrench", text: "Durch eigene komplexe Projekte bilden wir uns ständig weiter und bleiben dadurch auf dem aktuellen Stand."},
        {heading: "Erfahrenes Team", icon: "fas fa-users", text: "Aufgrund eines kompetenten Teams aus Entwicklern können wir flexibel auf Ihre Wünsche reagieren."}
    ];

    return (
        <div className="container-fluid" style={ containerStyle }>
            <Spring
                from={{ opacity: 0 }}
                to={{ opacity: 1 }}
                config={{ delay: 300, duration: 1000 }}
            >
                {props => (
                    <div style={props}>
                        <div className="row" style={ rowStyle }>
                            <div className="col-sm-4 reason-head">
                                <p className="reasons-catch-heading pull-right">Warum Sie mit uns nur profitieren können</p>
                            </div>
                            <div className="col-sm-8">
                            <div className="card-deck">
                            <div className="row">
                                {reasons.map(reason => (
                                    <div className="col-sm-3">
                                        <div className="reason-card">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="reason-card-head">
                                                        <div className="icon-circle">
                                                            <span className={ reason.icon }></span>
                                                        </div>
                                                        <p className="reason-heading">{ reason.heading }</p>
                                                    </div>
                                                    <hr/>
                                                    <p className="reason-paragraph">{ reason.text }</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            </div>
                            </div>
                        </div>
                    </div>
                )}
            </Spring>
        </div>);
}

var containerStyle = {
    backgroundColor: '#2f435e',
    'marginTop': 0,
    'paddingTop': '1rem',
    'paddingBottom': '2rem',
    'padding-right': '1rem'
}

var rowStyle = {
    'paddingTop': '1rem',
}
