import React from 'react';
import './App.css';

import Homepage from './components/Homepage.jsx';

function App() {
  return (
    <div className="WhistlingMartini">

      <Homepage/>

    </div>
  );
}

export default App;