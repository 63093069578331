import React from 'react';
import { Spring } from 'react-spring/renderprops'

export default function HomepagePillars()
{

    let pillars = [
        {icon: 'code', heading: 'Erstellen', text: 'Wir kümmern uns bei der Erstellung Ihrer Seite von A bis Z.'},
        {icon: 'palette', heading: 'Gestalten', text: 'Neuer Anstrich gefällig oder haben Sie eine Seite, die Ihnen gefällt? Wir sorgen dafür, dass Ihre Webseite Ihren Ansprüchen mehr als gerecht wird.'},
        {icon: 'server', heading: 'Hosten', text: 'Machen Sie es sich einfach und hosten Sie Ihre Webseite bei uns: Durch Backups und automatische Wordpress-Updates kann nichts schief gehen.'}
    ];

    return (
    <div className="container-fluid" style={ containerStyle }>
        <div className="row">
            <div className="col-sm-4"></div>
            <div className="col-sm-4 overlap-jumbotron">
                <div className="card leistungen-card">
                    <h2 style= { textCenter }>Unsere Leistungen</h2>
                </div>
            </div>
        </div>
        <Spring
            from={{ opacity: 0 }}
            to={{ opacity: 1 }}
            config={{ delay: 300, duration: 1500 }}
        >
            {props => (
                <div style={props}>
                    <div className="row" style={ rowStyle }>
                        <div className="card-group">

                            {pillars.map(pillar => (
                                <div key={pillar.icon} className="col-sm-4">
                                    <div className="card h-100 pillar-card">
                                        <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-1 pillar-card-icon-div">
                                                <p className="pillar-card-icon"><span className={'fas fa-' + pillar.icon}></span></p>
                                            </div>
                                            <div className="col-sm-5 pull-left align-middle">
                                                <h3 className="card-title pillar-card-title">{pillar.heading}</h3>
                                            </div>
                                        </div>
                                        <div className="row">
                                                <div className="col-sm-1"></div>
                                                <div className="col-sm-11 pull-left">
                                                    <p className="pillar-card-text card-text">
                                                        {pillar.text}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
            )}
        </Spring>
    </div>);
}

var containerStyle = {
    backgroundColor: '#FAFAFA',
    marginTop: 0,
    paddingTop: '1rem'
}

var rowStyle = {
    'paddingTop': '1rem',
    'paddingBottom': '4%',
    'padding': '1rem'
}

var textCenter = {
    'textAlign': 'center'
}