import React from 'react';
import { Link } from 'react-router-dom'

class Header extends React.Component {

    render()
    {
        var homepage = <Link to="/" className="navbar-brand"><strong>WhistlingMartini.de</strong></Link>;
        return (<div>
            <nav className="navbar navbar-expand-lg navbar-dark fixed-top">
            <div className="container">
              <Link to="/" className="navbar-brand"><strong>WhistlingMartini.de</strong></Link>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto">
                  <li className="nav-item">
                    <Link to="/" className="nav-link">Startseite</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/hosting" className="nav-link">Hosting</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/showcase" className="nav-link">Referenzen</Link>
                  </li>
                </ul>
              </div>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
              
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <span className="fas fa-at"></span><a href='mailto:info@whistlingmartini.de' className="site-link">info@whistlingmartini.de</a>
                  </li>
                  <li className="nav-item">
                   <span className="fas fa-phone"></span><a href='tel:+49 151 17428882' className="site-link">+49 151 17428882</a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>);
    }

}

export default Header;