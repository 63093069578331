import React from 'react';

//import background from '../../images/mohdammed-ali-9TUIfLylgw8-unsplash.jpg';
import background from '../../images/yasin-aribuga-_XVE6pKGiLE-unsplash.jpg';

class HomepageJumbotron extends React.Component
{
    render() {
        return (
        <div className="jumbotron jumbotron-fluid index-jumbotron" style={ essentialJumbotronStyle }>
            <div className="container" style={ jumbotronStyle }>
                <h1 className="display-4"><small>WhistlingMartini Softwareentwicklung</small></h1>
                <p className="lead">Ihr kompetenter Ansprechpartner im Thema Webseiten</p>
            </div>
        </div>);
    }
}

var essentialJumbotronStyle = {
    backgroundImage: `url(${background})`,
};

var jumbotronStyle = {
    color:'white',
    'paddingTop': '15%',
    'marginBottom': 0,
    'paddingBottom': '5%',
}

export default HomepageJumbotron;