import React from 'react'

export default function HomepageDeal(props) {
    var backgroundColor = props.backgroundColor 
            ? { backgroundColor: props.backgroundColor }
            : {};

    return (
        <div className="deal-card" style={backgroundColor}>
            <div className="deal-heading">
                <h2>{ props.dealName }</h2>
                <p className="deal-subheading">{ props.subHeading }</p>
            </div>
            <hr/>
            <div className="deal-text">
                <p>{ props.description }</p>
            </div>
        </div>
    )
}