import React from 'react'


export default function Jumbotron(props) {

    return (<div className="jumbotron jumbotron-fluid index-jumbotron" 
            style={{ backgroundImage: `url(${props.background})` }}>
        <div className="container" style={ jumbotronStyle }>
            <h1 className="display-4"><small>{ props.heading }</small></h1>
            <p className="lead">{ props.subheading }</p>
        </div>
    </div>);
    
}

var jumbotronStyle = {
    color:'white',
    'paddingTop': '15%',
    'marginBottom': '0 !important',
    'paddingBottom': '5%',
}