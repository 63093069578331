import React from 'react';
import { Spring } from 'react-spring/renderprops'

import HomepageDeal from './HomepageDeal.jsx';

export default function HomepageDeals()
{
    return (
    <div className="container-fluid" style={ containerStyle }>
        <div className="deals-heading pull-center">
            <p>Unsere Webdesign-Pakete</p>
        </div>
        <div className="row">
            <div className="col-sm-4">
                <HomepageDeal
                    dealName="Basic"
                    subHeading="Basis-Seite"
                    description="
                        Übernahme Ihrer alten Seiten inkl. Text und Design (so weit technisch möglich) in eine neue WordPress Seite.
                        Damit können Sie Ihre Inhalte in Zukunft einfach selbst pflegen und aktualisieren.
                        Optimieren der Seite für mobile Endgeräte. Bis zu 10 Unterseiten im Preis enthalten.
                    "
                />
            </div>
            <div className="col-sm-4">
                <HomepageDeal
                    dealName="Premium"
                    subHeading="Frischer Look"
                    description="
                        Ihre alte Webseite braucht einen neuen Anstrich?
                        Wir frischen Ihr Design auf und übernehmen Ihre Inhalte in eine neue WordPress Seite.
                        Übernahme oder Erstellung von bis zu 15 Seiten und Optimierung des Designs für mobile Endgeräte. 
                    "
                    backgroundColor="#95A9C4"
                />
            </div>
            <div className="col-sm-4">
                <HomepageDeal
                    dealName="Deluxe"
                    subHeading="Webseite + eigenes Design"
                    description="
                        Unsere Graphiker erstellen für Sie ein eigenes, individuelles Design.
                        Umsetzung Ihres Designs in WordPress und Erstellung Ihrer kompletten Webpräsenz –
                        inklusive Formularen, Optimierung für Suchmaschinen und Erstellung von bis zu 25 Seiten.
                    "
                />
            </div>
        </div>
    </div>);
}

var containerStyle = {
    backgroundColor: '#7C90AB',
    'marginTop': 0,
    'paddingTop': '1rem',
    'paddingBottom': '2rem',
    'padding-right': '1rem'
}