import React from 'react'
import { Spring } from 'react-spring/renderprops'

export default function HomepageContact() {
    return (
        <div className="container-fluid" style={ containerStyle }>
            <Spring
                from={{ opacity: 0 }}
                to={{ opacity: 1 }}
                config={{ delay: 300, duration: 1000 }}
            >
                {props => (
                    <div style={props}>
                        <div className="row" style={ rowStyle }>
                            <div className="col-sm-1"></div>
                            <div className="col-sm-5 white pull-right contact-col">
                                <p>
                                    <span className="reasons-catch-heading">Wieso zögern Sie noch?</span> <br></br>
                                    <span className="readable-text">
                                        Geben Sie ihrer bestehenden Webseite einen frischen Look und pflegen Sie die Inhalte ihrer Seite in Zukunft doch einfach selbst.
                                    </span>
                                </p>
                            </div>
                            <div className="col-sm-5 white contact-col-right">
                                <p>
                                    <span className="reasons-catch-heading">Kontaktieren Sie uns:</span> <br></br>
                                    <span className="readable-text">
                                        WhistlingMartini Softwareentwicklung GbR <br/>
                                        Lenaustraße 1 <br/>
                                        66125 Saarbrücken <br/> <br/>

                                        Email: <a href='mailto:info@whistlingmartini.de' className="site-link">info@whistlingmartini.de</a> <br/>
                                        Telefon: <a href='tel:+49 151 17428882' className="site-link">+49 151 17428882</a> <br/>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                )}
            </Spring>
        </div>);
}

var containerStyle = {
    backgroundColor: '#96AAC5',
    'marginTop': 0,
    'paddingTop': '1rem'
}

var rowStyle = {
    'paddingTop': '1rem',
}

var textCenter = {
    'textAlign': 'center'
}
